<template lang="pug">
.brand-wrapper
  .container-fluid
    .row(v-if="loading" style="height: calc(100vh - 8rem)")
      loading-logo
    template(v-else)
      om-heading(h1) {{ $t('ambassadorPayoutRequestPage.title') }}

      .row.mt-6
        .d-flex-1.flex-wrap
          .col-8.mb-3.pr-5
            .row
              .d-flex-1.flex-wrap
                .col-12.col-lg-12
                  .card.affiliate-informations-box
                    .affiliate-informations-box-header
                      .affiliate-informations-box-header-title {{ $t('ambassadorPayoutRequestPage.payoutItems') }}
                    .pt-6
                      AffiliateItemTable(v-if="payoutItems.length" :items="payoutItems")

            .row.affiliate-informations-box-row.d-flex.affiliate-informations-box-row-bordered.pt-0
              .col-12.affiliate-informations-box-col(align="right")
                span.pr-2 {{ $t('affiliate.payoutAmount') }}:
                span.font-weight-bold {{ affiliate.main.total_actual_can_payout }}
            .row
              .col-12.affiliate-informations-box-col.large.pt-5.d-flex.justify-content-end
                om-button.mr-2(secondary :to="{ name: 'ambassador_dashboard' }") {{ $t('ambassadorPayoutRequestPage.backButton') }}
                om-button.mr-2(primary :disabled="!enabledPayout" @click.prevent="payout") {{ $t('ambassadorPayoutRequestPage.sendButton') }}
            .row
              .col-7
              .col-5
                .mt-3.brand-under-heading-text.text-right {{ $t('ambassadorPayoutRequestPage.sendButtonInfo') }}

          .col-4.mb-3
            .row
              .col-12.col-lg-12.mb-5
                .card.affiliate-informations-box
                  .affiliate-informations-box-header
                    .affiliate-informations-box-header-title {{ $t('affiliate.payoutDetails') }}

                  .row.affiliate-informations-box-row.d-flex.pb-0
                    .col-6.affiliate-informations-box-col {{ $t('affiliate.commissionRate') }}
                    .col-6.affiliate-informations-box-col.font-weight-bold {{ affiliate.commissions[0].total }}
                    .col-6.affiliate-informations-box-col {{ $t('affiliate.minimumPayout') }}
                    .col-6.affiliate-informations-box-col.font-weight-bold {{ affiliate.main.minimum_payout }}

              .col-12.col-lg-12.mt-5.mb-5
                .card.affiliate-informations-box.affiliate-informations-box-invoicing
                  .affiliate-informations-box-header
                    .affiliate-informations-box-header-title {{ $t('affiliate.invoicingInfo') }}
                    router-link.ml-auto.text-decoration-none(:to="{ name: 'billing_address' }")
                      om-link(primary) {{ $t('edit') }}

                  .affiliate-informations-box-row.d-flex.pb-0
                    .col-6.affiliate-informations-box-col {{ $t('affiliate.name') }}
                    .col-6.affiliate-informations-box-col.font-weight-bold {{ affiliate.partner_data.aname }}

                  .affiliate-informations-box-row.d-flex.pb-0
                    .col-6.affiliate-informations-box-col {{ $t('affiliate.email') }}
                    .col-6.affiliate-informations-box-col.font-weight-bold {{ affiliate.partner_data.email }}

                  .affiliate-informations-box-row.d-flex.pb-0
                    .col-6.affiliate-informations-box-col {{ $t('postalCode') }}
                    .col-6.affiliate-informations-box-col.font-weight-bold {{ affiliate.partner_data.postcode }}

                  .affiliate-informations-box-row.d-flex.pb-0
                    .col-6.affiliate-informations-box-col {{ $t('city') }}
                    .col-6.affiliate-informations-box-col.font-weight-bold {{ affiliate.partner_data.city }}

                  .affiliate-informations-box-row.d-flex.pb-0
                    .col-6.affiliate-informations-box-col {{ $t('address') }}
                    .col-6.affiliate-informations-box-col.font-weight-bold {{ affiliate.partner_data.address }}

              .col-12.col-lg-12.mt-5.mb-5
                .card.affiliate-informations-box.affiliate-informations-box-invoicing
                  .affiliate-informations-box-header
                    .affiliate-informations-box-header-title {{ $t('ourInvoiceDetails.title') }}

                  .affiliate-informations-box-row.d-flex.pb-0
                    .col-6.affiliate-informations-box-col {{ $t('affiliate.name') }}
                    .col-6.affiliate-informations-box-col.font-weight-bold {{ $t('ourInvoiceDetails.name') }}

                  .affiliate-informations-box-row.d-flex.pb-0
                    .col-6.affiliate-informations-box-col {{ $t('address') }}
                    .col-6.affiliate-informations-box-col.font-weight-bold {{ $t('ourInvoiceDetails.address') }}

                  .affiliate-informations-box-row.d-flex.pb-0
                    .col-6.affiliate-informations-box-col {{ $t('euVatNumber') }}
                    .col-6.affiliate-informations-box-col.font-weight-bold {{ $t('ourInvoiceDetails.vat') }}

  om-modal(name="payout-detected-change-modal" :width="520" clickToClose)
    template(slot="modal-header")
      .row.m-0.p-0
        .font-weight-bold.font-size-1--25 {{ $t('ambassadorPayoutRequestPage.detectedChangeModal.title') }}
      .brand-modal-action-icon
        span.cursor-pointer(@click="$modal.hide('payout-detected-change-modal')")
          close-icon(:width="14" :height="14" :color="'#C2C2C2'")
    template(slot="modal-body")
      p
        span {{ $t('ambassadorPayoutRequestPage.detectedChangeModal.message') }}
    template(slot="modal-footer")
      .d-flex.justify-content-center.mt-2
        om-button(secondary @click="$modal.hide('payout-detected-change-modal')") {{ $t('ambassadorPayoutRequestPage.detectedChangeModal.cancelButton') }}
        om-button.ml-2(primary @click="refreshData(), $modal.hide('payout-detected-change-modal')") {{ $t('ambassadorPayoutRequestPage.detectedChangeModal.actionButton') }}
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import { stripTags } from '@/util';
  import affiliateDataMixin from '@/mixins/affiliate-data';
  import AffiliateItemTable from '@/components/Tables/AffiliateItemTable.vue';

  export default {
    components: { AffiliateItemTable },
    mixins: [affiliateDataMixin],

    data() {
      return {
        loading: true,
        changeHelper: null,
      };
    },

    computed: {
      ...mapState(['account']),
      partnerInfo() {
        return this.account.settings.affiliate.partnerInfo;
      },
      enabledPayout() {
        return this.affiliate.main.enable_new_payout;
      },
    },

    async mounted() {
      await this.fetchData();
      this.loading = false;
      this.updateChangeHelperValue();
    },

    methods: {
      stripTags,
      ...mapActions(['fetchAccount']),

      async refreshData() {
        this.loading = true;
        await this.fetchData();
        this.loading = false;
        this.updateChangeHelperValue();
      },

      updateChangeHelperValue() {
        this.changeHelper = this.affiliate.main.total_actual_can_payout;
      },

      async hasChanges() {
        const data = await this.fetchAffiliateData();
        const lastCanPayoutAmount = this.changeHelper;
        this.updateChangeHelperValue();
        return lastCanPayoutAmount !== data.main.total_actual_can_payout;
      },

      async fetchData() {
        await this.setAffiliateData();
        await this.setPayoutItems();
      },

      async payout() {
        this.loading = true;

        if (!this.validateBilling()) {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.updateBilling'),
            duration: 5000,
          });
          this.loading = false;

          return;
        }

        if (await this.hasChanges()) {
          this.$modal.show('payout-detected-change-modal');
          this.loading = false;
          return;
        }

        const success = await this.sendPayout();

        if (!success) {
          this.$notify({
            type: 'error',
            text: 'Something went wrong!',
            duration: 5000,
          });
          this.loading = false;
          return;
        }

        this.loading = false;

        this.$notify({
          type: 'success',
          text: this.$t('notifications.payoutSuccess'),
          duration: 5000,
        });

        return this.$router.replace({
          name: 'ambassador_dashboard',
          query: {
            successfulPayoutRequest: true,
            ts: Date.now(),
          },
        });
      },

      validateBilling() {
        return !(
          this.affiliate.partner_data.aname.length < 5 ||
          this.affiliate.partner_data.email.length < 8 ||
          this.affiliate.partner_data.postcode < 3 ||
          this.affiliate.partner_data.city.length < 3 ||
          this.affiliate.partner_data.address.length < 5
        );
      },
    },
  };
</script>

<style lang="sass">
  .affiliate-informations-box
    min-height: 0
</style>
